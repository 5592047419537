import styled from '@emotion/styled'
import React from 'react'
import { css } from 'theme-ui'
import { Box, BoxProps } from '../Box'

export type CardProps = BoxProps & {
  variant?: 'raised' | 'flat'
  children?: React.ReactNode
}

export const Card = styled(Box)<any>(({ variant = 'raised' }) =>
  css({
    backgroundColor: 'white',
    borderRadius: 'default',
    padding: 1,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparentBlack.10',
    boxShadow: variant === 'raised' ? 'default' : 'none',
  }),
)

Card.displayName = 'Card'
