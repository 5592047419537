import styled from '@emotion/styled'
import { ThemeUIStyleObject } from 'theme-ui'
import { Base } from '../Base/Base'

// TODO: Fix with JSX.IntrinsicElements[Tag]
export type BoxProps = {
  sx?: ThemeUIStyleObject
  as?: keyof React.ReactHTML // keyof JSX.IntrinsicElements
  href?: string
  onClick?: any
}

export const Box = styled(Base)<BoxProps>()
