import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import React from 'react'
import { css, ThemeUIStyleObject } from 'theme-ui'
import { variant } from '../../helpers'
import { Base } from '../Base'

export type TextProps = {
  variant?: 'jumbo' | 'h1' | 'h2' | 'h3' | 'h4' | 'body' | 'small'
  bold?: boolean
  color?: string
  children?: React.ReactNode
  uppercase?: boolean
  sx?: ThemeUIStyleObject
  overflow?: 'default' | 'clip' | 'ellipsis'
}

export const Text = styled(Base, { shouldForwardProp: isPropValid })<any>(
  ({ bold, uppercase, color }) =>
    css({
      fontWeight: bold ? 'bold' : undefined,
      textTransform: uppercase ? 'uppercase' : undefined,
      color,
    }),
  variant({
    prop: 'variant',
    variants: {
      jumbo: {
        fontSize: 6,
        fontWeight: 500,
        lineHeight: 1.2,
        fontFamily: 'heading',
      },
      h1: {
        fontSize: 5,
        fontWeight: 600,
        lineHeight: 1.3,
        fontFamily: 'heading',
      },
      h2: {
        fontSize: 4,
        fontWeight: 500,
        lineHeight: 1.4,
        fontFamily: 'heading',
      },
      h3: {
        fontSize: 3,
        fontWeight: 500,
        lineHeight: 1.4,
        fontFamily: 'heading',
      },
      h4: {
        fontSize: 2,
        fontWeight: 500,
        lineHeight: 1.5,
        fontFamily: 'heading',
      },
      body: {
        fontSize: 1,
        lineHeight: 1.5,
        fontFamily: 'body',
      },
      small: {
        fontSize: 0,
        lineHeight: 1.4,
        fontFamily: 'body',
      },
    },
  }),
  variant({
    prop: 'overflow',
    variants: {
      default: {},
      clip: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'clip',
      },
      ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  }),
)

Text.defaultProps = {
  as: 'span',
  variant: 'body',
}

export type HeadingProps = TextProps & {
  level: 1 | 2 | 3 | 4
}

export const Heading = ({ level = 1, ...restProps }: HeadingProps) => {
  const levelMap = {
    1: {
      variant: 'h1',
      element: 'h1',
    },
    2: {
      variant: 'h2',
      element: 'h2',
    },
    3: {
      variant: 'h3',
      element: 'h3',
    },
    4: {
      variant: 'h4',
      element: 'h4',
    },
  } as const
  const selectedLevel = levelMap[level]
  return <Text {...restProps} variant={selectedLevel.variant} as={selectedLevel.element} />
}
