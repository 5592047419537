import isPropValid from '@emotion/is-prop-valid'
import styled from '@emotion/styled'
import { CloseCircleIcon } from '@mm/company-ui-icons'
import React, { useCallback } from 'react'
import { css } from 'theme-ui'
import { variant } from '../../helpers'
import { Box, BoxProps } from '../Box'
import { Text } from '../Text'

export type InputProps = {
  name?: string
  variant?: 'default' | 'seamless' | 'large'
  placeholder?: string
  unitLabel?: string
  invalid?: boolean
  isClearable?: boolean
  value?: string
  type?: string
  containerProps?: BoxProps
  onChange?: (change: string) => void
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onFocus?: () => void
}

export type InputContainerProps = {
  variant: 'default' | 'seamless' | 'large'
  invalid: InputProps['invalid']
  theme: any
}

const InputContainer = styled(Box, { shouldForwardProp: isPropValid })<any>(
  ({ variant, invalid, theme }: InputContainerProps) => {
    const outlineColor = invalid ? theme.colors.system.red : theme.colors.transparentBlack[10]
    const blueOutlineColor = invalid ? theme.colors.system.red : theme.colors.lightBlue.default

    const variantCSS = {
      default: {
        boxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}`,
        hoveredBoxShadow: `inset 0 0 0 1px ${outlineColor}`,
        focusedBoxShadow: `inset 0 0 0 2px ${outlineColor}`,
      },
      seamless: {
        boxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}, ${theme.shadows.defaultTransparent}`,
        hoveredBoxShadow: `inset 0 0 0 ${invalid ? '1px' : '0'} ${outlineColor}, ${theme.shadows.defaultTransparent}`,
        focusedBoxShadow: `inset 0 0 0 1px ${outlineColor}, ${theme.shadows.default}`,
      },
      large: {
        boxShadow: `inset 0 -1px 0 ${outlineColor}`,
        hoveredBoxShadow: `inset 0 -2px 0 ${outlineColor}`,
        focusedBoxShadow: `inset 0 -2px 0 ${blueOutlineColor}`,
      },
    }[variant]

    return css({
      width: '100%',
      display: 'inline-flex',
      transition: 'all 100ms linear',
      fontFamily: 'body',

      boxShadow: variantCSS.boxShadow,
      '&:hover': {
        boxShadow: variantCSS.hoveredBoxShadow,
      },
      '&:focus-within': {
        boxShadow: variantCSS.focusedBoxShadow,
      },
    })
  },
  variant({
    prop: 'variant',
    variants: {
      default: {
        height: 7,
        backgroundColor: 'transparentBlack.5',
        borderRadius: 'default',
      },
      seamless: {
        height: 5,
        backgroundColor: 'transparent',
        borderRadius: 'default',

        '&:hover, &:focus': {
          backgroundColor: 'transparentBlack.5',
        },
      },
      large: {
        height: 10,
        fontSize: 4,
        backgroundColor: 'transparent',
      },
    },
  }),
)

const BaseInput = styled(Box)<any>(
  css({
    border: 'none',
    outline: 'none',
    flex: 1,
    backgroundColor: 'transparent',
  }),
  variant({
    prop: 'variant',
    variants: {
      default: {
        padding: 2,
      },
      seamless: {
        padding: 1,
      },
      large: {},
    },
  }),
)

const Indicators = styled(Box)<any>(
  css({
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  }),
  variant({
    prop: 'variant',
    variants: {
      default: {
        marginRight: 2,
      },
      seamless: {
        marginRight: 1,
      },
      large: {
        marginRight: 0,
      },
    },
  }),
)

export const Input = ({
  name,
  unitLabel,
  isClearable,
  containerProps,
  onChange,
  onKeyPress,
  placeholder,
  value,
  type,
  variant = 'default',
  invalid = false,
  onBlur,
  onFocus,
}: InputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => onKeyPress?.(event)
  const handleClear = useCallback(() => onChange?.(''), [onChange])
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event?.target?.value), [
    onChange,
  ])
  const handleBlur = useCallback(() => onBlur?.(), [onBlur])
  const handleFocus = useCallback(() => onFocus?.(), [onFocus])
  const handleIndicatorsClick = () => inputRef?.current?.focus()

  const renderIndicators = () =>
    unitLabel || isClearable ? (
      <Indicators variant={variant} onClick={handleIndicatorsClick}>
        {isClearable ? (
          <CloseCircleIcon
            width={2.5}
            height={2.5}
            display="block"
            color="transparentBlack.50"
            onClick={handleClear}
            style={{ cursor: 'pointer' }}
          />
        ) : null}
        {unitLabel ? <Text sx={{ color: 'transparentBlack.50' }}>{unitLabel}</Text> : null}
      </Indicators>
    ) : null

  return (
    <InputContainer variant={variant} invalid={invalid} {...containerProps}>
      <BaseInput
        variant={variant}
        as="input"
        ref={inputRef}
        onChange={handleChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
        onFocus={handleFocus}
        placeholder={placeholder}
        value={value}
        type={type}
        name={name}
      />
      {renderIndicators()}
    </InputContainer>
  )
}
