import React from 'react'
import { FieldPath, FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { Text } from '../Text'

export type FormItemProps = {
  children: React.ReactElement
  label?: string
}

export const FormItem = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  children,
  label,
  ...props
}: UseControllerProps<TFieldValues, TName> & FormItemProps) => {
  const {
    field,
    fieldState: { error, ...state },
  } = useController(props)

  return (
    <div>
      {label && (
        <Text
          as="div"
          sx={{
            fontSize: 0,
            lineHeight: 1.4,
            fontFamily: 'body',
            marginTop: 2,
            marginBottom: 1,
            fontWeight: 'bold',
            color: 'greyscale.700',
          }}
        >
          {label}
        </Text>
      )}
      {React.cloneElement(children, {
        ...field,
        ...state,
      })}
      {error && <Text sx={{ color: 'system.red' }}>{error.message}</Text>}
    </div>
  )
}
