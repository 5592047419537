import styled from '@emotion/styled'
import { CloseIcon } from '@mm/company-ui-icons'
import React from 'react'
import ReactModal from 'react-modal'
import { css } from 'theme-ui'
import { Box, BoxProps } from '../Box'
import { Card } from '../Card'
import { Heading, Text } from '../Text'

export type ModalProps = BoxProps & {
  isOpen?: boolean
  children?: React.ReactNode
  title?: string
  showCloseIcon?: boolean
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  onAfterOpen?: ReactModal.OnAfterOpenCallback
  onAfterClose?: (() => void) | undefined
  shouldCloseOnOverlayClick?: boolean
  shouldCloseOnEsc?: boolean
}

const BaseModal = styled(Card)(
  css({
    maxWidth: '598px',
    width: 'auto',
    margin: '0 auto',
    border: 'none',
    padding: 0,
    position: 'absolute',
    inset: '40px',
    background: 'white',
    overflow: 'auto',
    borderRadius: 'default',
    outline: 'none',
    height: 'max-content',
    top: '100px',
  }),
)

export const Modal = ({
  isOpen = false,
  title = '',
  showCloseIcon = true,
  onRequestClose,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  onAfterOpen,
  onAfterClose,
  children,
  ...props
}: ModalProps) => {
  return (
    <ReactModal
      {...props}
      shouldCloseOnEsc={shouldCloseOnEsc}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      isOpen={isOpen}
      ariaHideApp={false}
      onAfterOpen={onAfterOpen}
      onAfterClose={onAfterClose}
      onRequestClose={onRequestClose}
      className="_"
      overlayClassName="_"
      contentLabel={title}
      overlayElement={({ ref, ...props }, contentElement) => (
        <Box sx={{ position: 'fixed', zIndex: 9999999, inset: 0, backgroundColor: 'transparentBlack.20' }}>
          {contentElement}
        </Box>
        //   <ModalOverlay ref={ref} {...props}>
        //   {contentElement}
        // </ModalOverlay>
      )}
      contentElement={({ ref, ...props }, children) => (
        <BaseModal ref={ref} {...props}>
          <Box sx={{ padding: 3 }}>
            {title || showCloseIcon ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 3,
                  '& > .close': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Heading level={4}>{title}</Heading>
                {showCloseIcon && onRequestClose && (
                  <CloseIcon onClick={onRequestClose} className="close" color="transparentBlack.40" />
                )}
              </Box>
            ) : null}
            <Text>{children}</Text>
          </Box>
        </BaseModal>
      )}
    >
      {children}
    </ReactModal>
  )
}
