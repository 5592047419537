import { keyframes } from '@emotion/react'

export type theme = typeof visualRefreshTheme
declare module '@emotion/react' {
  export interface Theme extends theme {}
}

// Theme-ui only supports 2 breaking points
// const breakpoints = ['480px', '768px', '976px', '1440px']
const breakpoints = ['976px', '1440px']

export const visualRefreshTheme = {
  breakpoints,
  mq: breakpoints.map((bp) => `@media (min-width: ${bp})`),
  space: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  sizes: [0, 8, 16, 24, 32, 40, 48, 56, 64, 72, 80],
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Poppins, sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [11, 13, 16, 20, 25, 32, 40, 48],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    lightBlue: {
      100: '#ECF3F7',
      200: '#9DD1F4',
      300: '#67B8EE',
      400: '#3AA4EC',
      default: '#1392EA',
    },
    darkBlue: {
      70: '#EAEDF1',
      100: '#D2D7DD',
      200: '#A6B0BA',
      300: '#79899A',
      400: '#4D6278',
      default: '#1F3B55',
    },
    white: '#FFFFFF',
    black: '#31353D',
    greyscale: {
      white: '#FFFFFF',
      50: '#FAFAFA',
      100: '#F2F2F2',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#CDCDCD',
      500: '#9196A1',
      700: '#60646B',
      black: '#31353D',
    },
    transparentBlack: {
      5: 'rgba(0,0,0,0.05)',
      10: 'rgba(0,0,0,0.10)',
      20: 'rgba(0,0,0,0.20)',
      30: 'rgba(0,0,0,0.30)',
      40: 'rgba(0,0,0,0.40)',
      50: 'rgba(0,0,0,0.50)',
    },
    orange: {
      50: '#FFF3E0',
      300: '#FFB74D',
      default: '#FF9800',
      700: '#F57C00',
    },
    system: {
      red: '#FA4040',
      green: '#36DCA0',
    },
  },
  shadows: {
    default: '0px 12px 40px rgba(0, 0, 0, 0.16)',
    defaultTransparent: '0px 12px 40px rgba(0, 0, 0, 0)',
    none: 'none',
  },
  radii: {
    none: '0px',
    default: '0.375rem',
    medium: '1rem',
    large: '1.25rem',
    xl: '1.5rem',
    full: '9999px',
  },
  transition: {
    none: 'none',
    default: '0.3s ease',
    long: '0.6s ease',
  },
  animation: {
    none: 'none',
    default: keyframes({ from: { opacity: 0 }, to: { opacity: 1 } }),
  },
}

visualRefreshTheme.space[0.25] = 2
visualRefreshTheme.space[0.5] = 4
visualRefreshTheme.space[0.75] = 6
visualRefreshTheme.space[1.25] = 10
visualRefreshTheme.space[1.5] = 12
visualRefreshTheme.space[1.75] = 14
visualRefreshTheme.space[2.5] = 20

visualRefreshTheme.sizes[0.5] = 4
visualRefreshTheme.sizes[1.5] = 12
visualRefreshTheme.sizes[2.5] = 20
