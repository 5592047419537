import React from 'react'

type EventHandlers = {
  [K in keyof React.HTMLProps<unknown> & `on${string}`]?: React.HTMLProps<unknown>[K]
}

export type BaseProps = EventHandlers & {
  as?: keyof React.ReactHTML
  children?: React.ReactNode
  className?: string
  href?: string
  [key: `${'data' | 'aria'}-${string}`]: string | undefined
} & React.HTMLProps<unknown>

export const Base = React.forwardRef(({ as = 'div', className, children, ...rest }: BaseProps, ref) => {
  return React.createElement(
    as,
    {
      className,
      ref,
      ...rest,
    },
    children,
  )
})

Base.displayName = 'Base'
